import Image from 'next/image';
import React from 'react';
import Link from 'next/link';

import { cx } from '@emotion/css';

interface CardProps {
  styles: any;
  imageSrc: string;
  title: string;
  details: string;
  imageWidth?: number;
}
const Card = (props: CardProps) => {
  const { styles, imageSrc, title, details, imageWidth = 35 } = props;
  return (
    <div className={styles.wcu_point}>
      <div className={styles.wcu_p_img}>
        <Image
          src={imageSrc}
          alt="card image"
          width={imageWidth}
          height={35}
          layout="intrinsic"
        />
      </div>
      <div className={cx(styles.wcu_p_content, styles.wcu_content_wrapper)}>
        <h4 className={cx(styles.h4, styles.point_title, styles.text_accentl)}>
          {title}
        </h4>
        <p className={cx(styles.p, styles.point_desc, styles.text_accentl)}>
          {details}
        </p>
      </div>
    </div>
  );
};
const WhyChooseUs = ({ styles }: any) => {
  return (
    <section className={cx(styles.sec, styles.wcu_sec)}>
      <div className={cx(styles.container, styles.inner)}>
        <div className={styles.row}>
          <div
            className={cx(styles.heading_blk, styles.text_center, styles.w100)}>
            <h2 className={cx(styles.h2, styles.title, styles.text_accentl)}>
              Why choose us?
            </h2>
            <Link legacyBehavior href="/about-us" className={styles.link}>
              <a className={styles.link}>
                About Us
                <span className={styles.link_arrow}>
                  <Image
                    src="/images/icons/arrow-red.svg"
                    alt="trust pilot link arrow icon"
                    width={15}
                    height={12}
                  />
                </span>
              </a>
            </Link>
          </div>
        </div>
        <div className={cx(styles.row, styles.spaceb, styles.wcu_point_row)}>
          <Card
            styles={styles}
            imageSrc="/images/icons/home.svg"
            title="4.9 rating from 20k Jobs"
            details="Over 20,000 jobs completed since 2021, with a 4.9 rating on Trustpilot from happy property owners."
          />
          <Card
            styles={styles}
            imageSrc="/images/icons/simple_booking.svg"
            title="Quick, Simple Booking"
            imageWidth={65}
            details="Get a rapid quote and book your service in minutes - no back-and-forth emails or lengthy phone calls"
          />
        </div>
        <div className={cx(styles.row, styles.spaceb, styles.wcu_point_row)}>
          <Card
            styles={styles}
            imageSrc="/images/icons/users.svg"
            title="Verified Tradespeople"
            details="Every job is handled by fully vetted professionals you can trust."
          />
          <Card
            styles={styles}
            imageSrc="/images/icons/shield-plus.svg"
            title="Quality You Can Trust"
            details="Every job comes with our 12-month labor guarantee, giving you peace of mind long after the work is done."
          />
        </div>
        <div className={cx(styles.row, styles.spaceb, styles.wcu_point_row)}>
          <Card
            styles={styles}
            imageSrc="/images/icons/services.svg"
            title="15 Multiple Service Lines"
            imageWidth={65}
            details="From electrical work and plumbing to handyman services, we handle all your home maintenance needs under one roof."
          />
          <Card
            styles={styles}
            imageSrc="/images/icons/support.svg"
            title="Expert Support"
            imageWidth={65}
            details="Our customer support team responds quickly to your questions and keeps you updated at every step."
          />
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
